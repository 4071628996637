.btn {
  // border: none;
  // cursor: pointer;
  // outline: none !important;
  // text-decoration: none !important;
  // transition: .3s;
  // box-sizing: border-box;

  $color-chart: ();

  @function textColor($c, $k) {
    @if $c == "red" and $k >= 400 {
      @return #fff;
    }
    @else if $c == "pink" and $k >= 300 {
      @return #fff;
    }
    @else if $c == "purple" and $k >= 300 {
      @return #fff;
    }
    @else if $c == "deeppurple" and $k >= 300 {
      @return #fff;
    }
    @else if $c == "indigo" and $k >= 300 {
      @return #fff;
    }
    @else if $c == "red" and $k >= 400 {
      @return #fff;
    }
    @else if $c == "blue" and $k >= 500 {
      @return #fff;
    }
    @else if $c == "lightblue" and $k >= 600 {
      @return #fff;
    }
    @else if $c == "cyan" and $k >= 700 {
      @return #fff;
    }
    @else if $c == "teal" and $k >= 500 {
      @return #fff;
    }
    @else if $c == "green" and $k >= 600 {
      @return #fff;
    }
    @else if $c == "lightgreen" and $k >= 700 {
      @return #fff;
    }
    @else if $c == "lime" and $k >= 900 {
      @return #fff;
    }
    @else if $c == "orange" and $k >= 800 {
      @return #fff;
    }
    @else if $c == "deeporange" and $k >= 500 {
      @return #fff;
    }
    @else if $c == "brown" and $k >= 300 {
      @return #fff;
    }
    @else if $c == "grey" and $k >= 600 {
      @return #fff;
    }
    @else if $c == "bluegrey" and $k >= 400 {
      @return #fff;
    }
    @else if $c == "black" {
      @return #fff;
    }
    @else {
      @return rgba(0,0,0, 0.9);
    }
  }

  @mixin setColor($c) {

    @if $c == "red" {
      $color-chart: $red;
    }
    @else if $c == "pink" {
      $color-chart: $pink;
    }
    @else if $c == "purple" {
      $color-chart: $purple;
    }
    @else if $c == "deeppurple" {
      $color-chart: $deeppurple;
    }
    @else if $c == "indigo" {
      $color-chart: $indigo;
    }
    @else if $c == "blue" {
      $color-chart: $blue;
    }
    @else if $c == "lightblue" {
      $color-chart: $lightblue;
    }
    @else if $c == "cyan" {
      $color-chart: $cyan;
    }
    @else if $c == "teal" {
      $color-chart: $teal;
    }
    @else if $c == "green" {
      $color-chart: $green;
    }
    @else if $c == "lightgreen" {
      $color-chart: $lightgreen;
    }
    @else if $c == "lime" {
      $color-chart: $lime;
    }
    @else if $c == "yellow" {
      $color-chart: $yellow;
    }
    @else if $c == "amber" {
      $color-chart: $amber;
    }
    @else if $c == "orange" {
      $color-chart: $orange;
    }
    @else if $c == "deeporange" {
      $color-chart: $deeporange;
    }
    @else if $c == "brown" {
      $color-chart: $brown;
    }
    @else if $c == "grey" {
      $color-chart: $grey;
    }
    @else if $c == "bluegrey" {
      $color-chart: $bluegrey;
    }
    @else if $c == "black" {
      $color-chart: $black;
    }
    @else if $c == "white" {
      $color-chart: $white;
    }

    @each $k, $v in $color-chart {
      @if $color-chart == $black or $color-chart == $white {
        @include _type($k, $v, $c);
      }
      @else {
        &#{$k} {
          @include _type($k, $v, $c);
        }
      }
    }
  }

  @mixin _type($key, $value, $color) {
    background: $value;
    &_ftg {
      color: textColor($color, $key) !important;
      background: $value;
      &:hover,
      &:focus  {
        background: adjust-color($value, $alpha: -0.4);
      }
      &:active {
        background: adjust-color($value, $alpha: -0.2);
      }
      &:disabled {
        color: adjust-color(#000, $alpha: -0.7);
        background: adjust-color(#000, $alpha: -0.8);
      }
    }
    &_rsd {
      color: textColor($color, $key) !important;
      background: $value;
      &:hover,
      &:focus  {
        background: darken($value, 10%);
      }
      &:active {
        background: darken($value, 20%);
      }
      &:disabled {
        color: adjust-color(#000, $alpha: -0.7);
        background: adjust-color(#000, $alpha: -0.8);
      }
    }
    &_flt {
      color: $value !important;
      &:hover,
      &:focus  {
        background: adjust-color($value, $alpha: -0.8);
      }
      &:active {
        background: adjust-color($value, $alpha: -0.6);
      }
      &:disabled {
        color: adjust-color($value, $alpha: -0.7);
      }
    }
  }

  @mixin -color {
    @each $c in $color-list {
      &-#{$c} {
        @include setColor($c);
      }
    }
  }

  @include -color;

}
[class$="_ftg"],
[class*="_ftg "] {
  border-radius: 50%;
  display: inline-block;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  padding: 8px;
  box-shadow: 0 0 4px rgba(0,0,0, .14),0 4px 8px rgba(0,0,0, .28);
  &:active {
    box-shadow: 0 1px 2px 0 rgba(0,0,0, .12),0 1px 1px 0 rgba(0,0,0, .24);
  }
  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }
}
a[class$="_ftg"],
a[class*="_ftg "] {
  display: -webkit-inline-flex;
  display: inline-flex;
}
[class$="_rsd"],
[class*="_rsd "] {
  box-shadow: 0 1px 2px 0 rgba(0,0,0, .12),0 1px 1px 0 rgba(0,0,0, .24);
  &:active {
    box-shadow: 0 0 4px rgba(0,0,0, .14),0 4px 8px rgba(0,0,0, .28);
  }
  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }
}
[class$="_flt"],
[class*="_flt "] {
  background: none;
  &:disabled {
    cursor: not-allowed;
    background: none;
  }
}
