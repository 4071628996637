@charset "UTF-8";

$color-list: "red", "pink", "purple", "deeppurple", "indigo", "blue", "lightblue",
  "cyan", "teal", "green", "lightgreen", "lime", "yellow", "amber", "orange",
  "deeporange", "brown", "grey", "bluegrey", "black", "white";

body {
	font-family: 'Lato','YuGothic', ‘游ゴシック’;
}

// ユーザ−登録
#createuser {
	.checkbox input[type=checkbox] {
		margin-left:0 !important;
	}
}

.fa-btn {
	margin-right: 6px;
}
.btncolors {
	margin: 2px;
}

// カレンダーの休日
.datepicker {
	td.disabled {
		background-color: rgba(200,100,100,0.1) !important;
	}
}
.bootstrap-datetimepicker-widget {
	td.disabled {
		background-color: rgba(51, 123, 184,0.1) !important;
	}
}

// ゲームカレンダー
.gsv_calendar {
	.calendar-dow {
		height: 100px;
	}
	.panel {
		margin:0 3px 0;
	}
	.panel-heading {
		padding: 3px 0;
		font-size: 0.8em;
	}
	.panel-body {
		padding:2px;
	}
}

// ホワイトボード
#schedules {
	th {
		text-align: center;
		&:first-child,
		&:last-child {
			text-align: left;
		}
	}
	th, td {
		vertical-align: middle;
	}
	.empname {
		width: 105px;
		text-align: left;
		a {
			color: #000;
		}
	}
	.contact {
		width: 30px;
	}
	.time,
	.status {
		width: 80px;
		text-align: center;
		.btn {
			width: 80px;
		}
	}
	.form-group {
		margin-bottom:0;
	}
	.on-hold {
		font-size:9px;
		line-height: 1;
	}
}

// 承認印
.sig {
	border:solid 1px #ccc;
	padding:3px;
	width:80px;
	height:100px;
	text-align: center;
	float:left;
	margin:0 3px 10px;
	line-height: 1.5;
	.sig-title {
		display:inline-block;
		width:100%;
		border-bottom: solid 1px #ccc;
	}
	.seal {
		width:70px;
		height:70px;
		margin:2px auto;
		border-radius: 50px;
		color:rgba(217,51,63,0.9);
		border:solid 3px rgba(217,51,63,0.9);
		position: relative;
		overflow: hidden;
		.sig-sts,
		.sig-date {
			display:inline-block;
			width:100%;
			border-bottom:solid 1px rgba(217,51,63,0.9);
		}
		.sig-date {
			font-size:0.8em;
			line-height: 1.7;
		}
		.sig-sts,
		.sig-name {
			font-family:"正楷書CB1";
		}
	}
}
// 申請書編集(SU)
dl.editwf {
	dd {
		margin-bottom:15px;
		.form-group {
			margin-bottom:0;
		}
		.help-block {
			margin-top:0;
			margin-bottom:0;
		}
	}
}
.auth-group {
	margin-bottom: 15px;
	dl {
		margin-bottom:0;
		dd {
			margin-bottom:0;
		}
	}
}

// 5Sカイゼン　メモ
.memo5s {
	width: 400px;
	border-collapse: collapse;
	margin: 0.5em;
	td {
		width: 50%;
		padding: 8px;
		border: solid 3px gray;
		font-weight: bold;
		text-align: center;
		&.gray {
			background-color: #ccc;
		}
		&.graycom {
			background-color: #666;
			color:#fff;
		}
	}
}


// 社内サーバ
.iframeblock {
	height: 1000px;
	.iframebody {
		height: 100%;
		iframe {
			border:none;
			width: 100%;
			height: 100%;
			padding:0;
			margin:0;
		}
	}
}

// 災害対策
.web171 {
	color: #c00 !important;
	font-size: 130%;
}
dl.sms {
	dd {
		padding: 0 0 10px 0;
	}
}
dl.anpi-app {
	// float: left;
	dt {
		font-size: 110%;
		padding-bottom: 10px;
		&:not(:first-child){
			margin-top: 20px;
		}
		clear: both;
		// width: 80px;
		// height: 80px;
	}
	dd {
		height: 100px;
		margin-left: 20px;
		float:left;
		// vertical-align: bottom;
		// display: inline-block;
		// &:nth-child(odd){
		// 	margin-left: 0;
		// }
		a {
			display: block;
		}
	}
	// dd:nth-child(even){
	// 	float:left;
	// }
	img.appimg {
		width: 80px;
		height: auto;
		-webkit-border-radius: 20%;
		-moz-border-radius: 20%;
		-ms-border-radius: 20%;
		-o-border-radius: 20%;
		border-radius: 20%;
		margin-right: 20px;
	}
	img.applestore {
		height: 40px;
		margin: 0 10px 10px 0;
	}
	img.googleplay {
		height: 40px;
	}
}
